import { StarTutoringDisabled } from 'components/pages/StarTutoring/pages/StarTutoringDisabled'
import StarTutoringHome from 'components/pages/StarTutoring/pages/StarTutoringHome'
import { isApp } from 'pages/embed'
import React from 'react'
import Layout from '../../layouts/layout'

export default () => {
  if (typeof window === 'undefined') return null
  return (
    <Layout>{isApp() ? <StarTutoringDisabled /> : <StarTutoringHome />}</Layout>
  )
}

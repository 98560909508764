import { navigate } from 'gatsby'
import { Button } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  max-width: 440px;
  margin: 0 auto;
  line-height: 1.3;
  text-align: center;

  font-size: 1.3em;
  h1 {
    font-size: 2em;
  }
`

export const StarTutoringDisabled = () => {
  return (
    <Wrapper>
      <h1>Advanced Tutoring</h1>
      <p>
        In-depth, annotated, and personalised feedback on special writing
        assignments from a dedicated tutor.
      </p>
      <p>Unfortunately, this is unavailable in this app.</p>
      <Button
        theme="primary"
        size="regular"
        onClick={() => {
          navigate('/')
        }}
      >
        Home
      </Button>
    </Wrapper>
  )
}
